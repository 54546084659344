import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

const baseUrl = "https://julliehuwelijksdag.nl";

declare module 'vue/types/vue' {

  interface Vue {
    $api: string;
  }

  interface VueConstructor {
    $api: string;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    $api?: string;
  }
}

Vue.prototype.$api = baseUrl;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
