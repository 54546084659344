





































































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "App",

  data: () => ({
    drawer: false,
  }),

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
});
