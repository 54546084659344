import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: null,
      id: null,
      email: null,
      affiliate: null,
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    async isAuthenticated({ commit }) {
      console.log("check auth");

      const response = await axios.get(`https://julliehuwelijksdag.nl/v2/auth`);
      console.log(response);
      
      commit('SET_USER', response.data)
    }
  },
  modules: {
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
})