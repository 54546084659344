







import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "Login",

  components: {},

  methods: {
    login() {
      // this.$store.dispatch("setUser", {
      //   name: "Daan",
      //   id: "1",
      //   email: "daan@",
      // });
      // this.$store.dispatch("isAthenticated");
      axios
        .post(`https://julliehuwelijksdag.nl/login`, {
          username: "daan",
          password: "pas",
        }, {
          withCredentials: true,
        })
        .then(async (response) => {
          console.log("Login", response.data);
          this.$store.dispatch("setUser", response.data);
          // this.$router.push("/home");
          const response2 = await axios.get('https://julliehuwelijksdag.nl/v2/auth', {
            withCredentials: true,
          });
          console.log(response2.data);
          
        });

      // this.$router.push("/home");
    },
  },
});
